<template>
  <div class="row justify-content-center" id="Login">
    <div class="col-md-12 log-form">
      <v-img
        style="width: 100%; height: 100%; position: absolute;"
        src="../../assets/image/background1.png"
      ></v-img>
      <v-card
        elevation="10"
        class="mx-auto"
        style="padding:30px; margin-top:60px;"
      >
        <div
          style="position:absolute; top:-100px; left:0; width:100%; text-align:center;"
        >
          <router-link to="/">
            <v-btn
              style="height:50px;width:50px; margin-bottom:5px; "
              elevation="5"
              fab
              x-large
              color="white"
              class=" rounded-circle"
              ><v-img
                alt="sanqua multi international"
                class=" rounded-circle"
                contain
                src="../../../public/sanquamulti.png"
                transition="scale-transition"
                style="height:50px;width:50px; "
            /></v-btn>
          </router-link>
          <h5 style="color: rgba(0,0,0,0.5);">
            e-Sanqua
          </h5>
        </div>
        <h3
          style="display: flex; justify-content: flex-start; align-items:center; width: 130px;"
        >
          Sign in
        </h3>
        <div style="position:relative;top:-5px;">
          <p style="width:385px; height:48px;"></p>
          <v-alert
            dense
            outlined
            type="error"
            style="width:100%;position:relative; top:-25px;"
            v-if="isFailed"
          >
            {{ failMsg }}
          </v-alert>
        </div>
        <b-form style="position:relative; top:-30px;" @submit.prevent="login">
          <label
            for="text-email"
            style="width:100%; text-align:left; padding-left: 10px"
            >Email</label
          >
          <b-form-input
            type="email"
            id="text-email"
            v-model="form.email"
            style="margin-bottom: 20px;"
          ></b-form-input>
          <label
            for="text-password"
            style="width:100%; text-align:left; padding-left: 10px"
            >Password</label
          >
          <b-form-input
            type="password"
            id="text-password"
            style="margin-bottom: 20px;"
            v-model="form.password"
          ></b-form-input>
          <div
            class="col-12"
            style="text-align: right; padding: 0; position:relative; top:30px;"
          >
            <button type="submit">Sign in</button>
            <router-link to="/forgot-password" disabled
              >Forgot Password?</router-link
            >
          </div>
        </b-form>

        <div
          v-if="loading"
          class="d-flex justify-center align-center"
          style="position:absolute; top:0; left:0; width:100%;background:rgba(255,255,255,.5);height:100%;"
        >
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </v-card>
      <hr />
      <!-- <p
        style="text-align: center; font-size: 14px; position: relative; z-index:2; color: red;"
      >
        Doesn't have an account?
        <router-link
          to="/register"
          style="font-size: 16px; color:white;"
          >Create now</router-link
        >
      </p> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

import buildType from '../../services/buildType'
export default {
  name: 'login',
  data() {
    return {
      oAuth: buildType.apiURL('oauth'),
      oAuth2: buildType.apiURL('oauth2'),
      loading: false,
      isFailed: false,
      failMsg: '',
      form: {
        email: '',
        password: ''
      }
    }
  },
  computed: {
    ...mapGetters([])
  },
  created() {
    if (this.$route.path === '/oauth/authorize') {
      localStorage.setItem('xOthState', this.$route.query.state)
    }
  },
  methods: {
    ...mapActions(['adminLogin']),
    async login() {
      if (!this.form.email && !this.form.password) {
        this.isFailed = true
        this.failMsg = 'Email & Password is required'
        setTimeout(() => {
          this.isFailed = false
        }, 3000)
      } else {
        if (this.$route.path === '/login') {
          this.loading = true
          await this.adminLogin(this.form)
            .then(results => {
              if (results.data.status_code === '-99') {
                this.isFailed = true
                this.failMsg = results.data.status_msg

                this.form.email = ''
                this.form.password = ''

                setTimeout(() => {
                  this.isFailed = false
                }, 3000)
              } else {
                this.form.email = ''
                this.form.password = ''
              }
            })
            .catch(error => {
              this.isFailed = true
              this.failMsg = error
                ? 'Cant connect to server'
                : 'Cant connect to server'

              setTimeout(() => {
                this.isFailed = false
              }, 3000)
              localStorage.removeItem('token')
              this.form.email = ''
              this.form.password = ''
            })

          this.loading = false
        } else {
          this.oAuth2Login(this.form)
        }
      }
    },
    home() {
      this.$router.push('/')
    },
    async oAuth2Login(payload) {
      if (this.$route.path === '/oauth/authorize') {
        const instance = axios.create()
        const newPayload = {
          email: payload.email,
          password: payload.password,
          response_type: this.$route.query.response_type,
          client_id: this.$route.query.client_id,
          redirect_uri: this.$route.query.redirect_uri,
          scope: this.$route.query.scope,
          state: this.$route.query.state
        }

        this.loading = true
        await instance
          .post(`${this.oAuth2}user/login`, newPayload)
          .then(res => {
            console.log(res)
            if (res.data.status_code === '-99') {
              this.isFailed = true
              this.failMsg = res.data.status_msg

              this.form.email = ''
              this.form.password = ''
              this.loading = false
              setTimeout(() => {
                this.isFailed = false
              }, 2000)
            } else {
              this.form.email = ''
              this.form.password = ''
              this.credentialCheck(
                res.data.authorization_code,
                newPayload.redirect_uri,
                res.data.state
              )
            }
            return null
          })
          .catch(err => {
            this.isFailed = true
            this.failMsg = err
              ? 'Cant connect to server'
              : 'Cant connect to server'

            this.form.email = ''
            this.form.password = ''
            this.loading = false
            setTimeout(() => {
              this.isFailed = false
            }, 2000)
            return null
          })
        // ------ hit api login
      } else {
        this.isFailed = true
        this.failMsg = 'Parameter is invalid'

        setTimeout(() => {
          this.isFailed = false
        }, 3000)
      }
    },
    async credentialCheck(code, uri, state) {
      this.loading = false
      if (state === localStorage.getItem('xOthState')) {
        localStorage.removeItem('xOthState')
        window.open(`${uri}?state=${state}&code=${code}`, '', '', false)
        // window.location.href = `${uri}?state=${state}&code=${code}`
      } else {
        this.isFailed = true
        this.failMsg = "Credential doesn't match"
        setTimeout(() => {
          localStorage.removeItem('xOthState')
          this.isFailed = false
        }, 2000)
      }
      // const payload = {
      //   client_id: id,
      //   redirect_uri: uri,
      //   state: code
      // }
      // await instance
      //   .post(
      //     'http://10.10.20.5:9189/api/oauth2/v1/client/check_credential',
      //     payload
      //   )
      //   .then(res => {
      //     console.log(res)
      //     if (res.data.status_code === '-99') {
      //       this.isFailed = true
      //       this.failMsg = res.data.status_msg
      //       setTimeout(() => {
      //         this.isFailed = false
      //         this.loading = false
      //       }, 1000)
      //     } else {
      //       setTimeout(() => {
      //         window.open(
      //           `${uri}?state=${state}&code=${code}`,
      //           '_blank',
      //           '',
      //           true
      //         )
      //         this.loading = false
      //       }, 1000)
      //     }
      //     return null
      //   })
      //   .catch(err => {
      //     console.log(err)
      //     this.isFailed = true
      //     this.failMsg = err
      //       ? 'Cant connect to server'
      //       : 'Cant connect to server'

      //     setTimeout(() => {
      //       this.isFailed = false
      //       this.loading = false
      //     }, 1000)
      //     return null
      //   })
    }
  }
}
</script>

<style lang="scss" src="../../assets/css/login.scss"></style>
